import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${({ theme }) => theme.colors.bg1};
  box-shadow: 0px 2px 10px rgba(171, 133, 115, 0.16);
  border-radius: 20px;
  padding: 1rem;
  
  border-radius: 20px;
  border: 1px solid rgba(48, 213, 193, 1);
  box-sizing: border-box;
  background: rgba(33, 52, 62, 1);
  box-shadow: 0px 3px rgba(31, 255, 255, 1);
  overflow: hidden;
  margin: 0 auto;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, style }: { children: React.ReactNode, style?: any}) {
  return <BodyWrapper style={style || {}}>{children}</BodyWrapper>
}
