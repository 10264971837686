import React, { useCallback, useState } from 'react'
import { HelpCircle as Question } from 'react-feather'
import styled from 'styled-components'
import Tooltip from '../Tooltip'
import QIcon from '../../assets/images/icon_ques.png'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  // background-color: ${({ theme }) => theme.colors.bg2};
  color: ${({ theme }) => theme.colors.text2};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

export default function QuestionHelper({ text, size }: { text: string, size?: number }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4 }}>
      <Tooltip text={text} show={show}>
        <QuestionWrapper onClick={() => {
          if (text) {
            open()
          }        
        }} onMouseEnter={() => {
          if (text) {
            open()
          }   
        }} onMouseLeave={close}>
          {/* <Question size={16} /> */}
          <img src={QIcon} style={{
            width: (size || 16) + 'px'
          }} />
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
