import React, { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { Pair } from '@pancakeswap-libs/sdk'
import { Link } from 'react-router-dom'
import { SwapPoolTabs } from '../../components/NavigationTabs'

import Question from '../../components/QuestionHelper'
import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE } from '../../components/Shared'
import { Text } from 'rebass'
import { LightCard } from '../../components/Card'
import { RowBetween } from '../../components/Row'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import AppBody from '../AppBody'
import { Dots } from '../../components/swap/styleds'
import TranslatedText from '../../components/TranslatedText'
import { TranslateString } from '../../utils/translateTextHelpers'
import { isMobile } from 'react-device-detect'
import { useI18n } from '../../i18n/i18n-react'

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens, chainId), tokens })),
    [trackedTokenPairs, chainId]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()

  const i18n = useI18n()

  return (
    <div style={{
      minHeight: isMobile ? 'calc(100vh - 270px - 70px)' : 'calc(100vh - 270px)',
      maxWidth: 1000,
      width: '100%',
      margin: '0 auto',
    }}>
      <AppBody style={{
        padding: '0'
      }}>
        {/* <SwapPoolTabs active={'pool'} /> */}
        <AutoColumn gap="lg" justify="center">
          {/* <ButtonPrimary id="join-pool-button" as={Link} style={{ padding: 16 }} to="/add/ETH">
            <Text fontWeight={500} fontSize={20}>
              <TranslatedText translationId={202}>Add Liquidity</TranslatedText>
            </Text>
          </ButtonPrimary> */}
          <RowBetween padding={'16px 16px'} style={{
            borderBottom: '1px solid rgb(48, 213, 193)'
          }}>
            <Text color={theme.colors.text1} fontWeight={500}>
              {i18n("Your Liquidity", "Your Liquidity")}
            </Text>
            <Question
              text={i18n(
                'Whenyou2',
                'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
              )}
            />
          </RowBetween>
          <div style={{
            width: '100%',
            padding: '0 16px 16px'
          }}>
            <AutoColumn gap="12px" style={{ width: '100%' }}>

              {!account ? (
                <div style={{
                  background: 'rgb(33, 52, 62)'
                }}>
                  <TYPE.body color={theme.colors.text3} textAlign="center">
                    {i18n('Connect to a wallet to view your liquidity.', 'Connect to a wallet to view your liquidity.')}
                  </TYPE.body>
                </div>
              ) : v2IsLoading ? (
                <div style={{
                  background: 'rgb(33, 52, 62)'
                }}>
                  <TYPE.body color={theme.colors.text3} textAlign="center">
                    <Dots>{i18n("Loading", "Loading")}</Dots>
                  </TYPE.body>
                </div>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {allV2PairsWithLiquidity.map(v2Pair => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                </>
              ) : (
                <div style={{
                  background: 'rgb(33, 52, 62)'
                }}>
                  <TYPE.body color={theme.colors.text3} fontSize={15} textAlign="center">
                    {i18n("No liquidity found.", "No liquidity found.")}
                  </TYPE.body>
                </div>
              )}

              <div>
                <Text textAlign="center" fontSize={15} color="rgb(164, 193, 202)" style={{ padding: '.5rem 0 .5rem 0' }}>
                  {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : i18n("Don't see a pool you joined?", "Don't see a pool you joined?")}{' '}
                  <StyledInternalLink id="import-pool-link" to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                    {hasV1Liquidity ? i18n('migratenow', 'Migrate now.') : i18n('306', 'Import it.')}
                  </StyledInternalLink>
                </Text>
              </div>
            </AutoColumn>
            {/* <ButtonPrimary id="join-pool-button" as={Link} style={{ padding: 16 }} to="/add/ETH">
            <Text fontWeight={500} fontSize={20}>
              <TranslatedText translationId={202}>Add Liquidity</TranslatedText>
            </Text>
          </ButtonPrimary> */}
            <ButtonPrimary id="join-pool-button" as={Link} style={{ marginTop: 16, padding: 16, background: 'rgb(35, 132, 126)' }} to="/add/ETH">
              <Text fontWeight={500} fontSize={15} color={'rgb(227, 245, 246)'}>
                {i18n("AddLiquidity", "Add Liquidity")}
                {/* <TranslatedText translationId={202}>Add Liquidity</TranslatedText> */}
              </Text>
            </ButtonPrimary>
          </div>
        </AutoColumn>
      </AppBody>
    </div>
  )
}