import { ChainId } from '@pancakeswap-libs/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.HECO_MAINNET]: '0x8dd2f8105Dbaf80821fC5085EBa50Ca7927C6bdf', // TODO
  [ChainId.HECO_TESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
  [ChainId.OEC_MAINNET]: '0x9c3bc898fdD0651351ec911b22f69dF7D21BdE8f',
  [ChainId.POLYGON]: '0x47aa48179fc3107Da043Dea6B7f20Ed7Ff3A9Ed5',
  [ChainId.BSC_MAINNET]: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  [ChainId.BSC_TESTNET]: '0xae11c5b5f29a6a25e955f0cb8ddcc416f522af5c',
  [ChainId.CORE_MAINNET]:'0xDa9713c4d53FB3F0D2a2D120DD7df3F3C6c0dCBD'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
