import React, { useEffect, useState } from "react"
import { ethers } from "ethers"
import { abi, localAddress, shopAddress, abiSwap } from "../abi"
import "./coy-cell.css"
import axios from "axios"
import { useHistory } from "react-router"

const CoyCell = ({
    item,
    cellType = 1,
    clickEvent
}: {
    item: any,
    cellType?: number // 1默认 2从合约拉取详情 3从链接拉取详情
    clickEvent?: () => void
}) => {

    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const contract = new ethers.Contract(item.nftAddress, abi, provider.getSigner())
    const shopContract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())
    const history = useHistory()
    const [nftInfo, setNftInfo] = useState({})

    const getNftInfoFromToken = async () => {
        const result = await contract.tokenURI(ethers.utils.parseUnits(item.id, 0))
        const info = await axios(result)
        if (info && info.data) {
            setNftInfo(info.data)
        }
    }

    const getNftInfoFromUrl = async () => {
        axios.get(item.url).then(res => {
            if (res) {
                setNftInfo(old => {
                    return {
                        ...old,
                        ...res.data
                    }
                })
            }
        })
        if (!item.price) {
            const n = await shopContract.nftList(item.nftAddress, ethers.utils.parseUnits(item.id, 0))
            console.log('bbb', parseInt(n[1].toString()))
            if (n && n.length >= 2) {
                setNftInfo(old => {
                    return {
                        ...old,
                        price: parseInt(n[1].toString())
                    }
                })
            }
        }
    }

    useEffect(() => {
        try {
            if (cellType == 2) {
                getNftInfoFromToken()
            }
            if (cellType == 3) {
                getNftInfoFromUrl()
            }
        } catch (err) { }
    }, [cellType, item])

    const getPrice = () => {
        if (!nftInfo || !item) {
            return ''
        }
        if (cellType == 3 && nftInfo.price != undefined) {
            return (nftInfo.price + '' || '') / 1e18
        }
        if (item.price) {
            return (item.price + '' || '') / 1e18
        }
        return ''
    }

    return <div className="home__list__item" onClick={() => {
        if (clickEvent) {
            clickEvent()
        } else {
            if (cellType == 2) {
                history.push('/detail?addr=' + item.nftAddress + '&id=' + item.id)
            } else {
                window.location.href = '/#/detail?addr=' + item.nftAddress + '&id=' + item.id
            }
        }
    }}>
        <div className="home__list__item__wrap">
            <img className="home__list__item__img" src={item.image || nftInfo.image} />
            <div className="home__list__item__title">{(item.name || nftInfo.name) + (item.id ? ' #' + item.id : '')}</div>
            <div className="home__list__item__subtitle">
                <div className="home__list__item__sublogo">
                    <img src="https://token-monitor.s3.amazonaws.com/1695037610133icon.png" alt="" />
                </div>
                <span>{getPrice()}</span>
            </div>
        </div>
    </div>
}

export default CoyCell