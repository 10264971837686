import React, { useContext, useEffect, useState } from "react"
import LogoImg from '../../../assets/images/coy.png'
import { LanguageContext } from "../../../hooks/LanguageContext"
import "./header.css"
import { EN, ZHCN } from "../../../constants/localisation/languageCodes"
import { useI18n, useLanguage } from "../../../i18n/i18n-react"
import { ZH_CN } from "../../../i18n/languageCode"
import { ethers } from "ethers"
import { useLocation } from "react-router-dom"
import { abi, localAddress, localAddress2 } from "../abi"

export const initWeb3Provider = () => {
    let provider = null
    if (window.ethereum) {
        provider = new ethers.providers.Web3Provider(window.ethereum)
    } else {
        provider = new ethers.providers.JsonRpcProvider('https://rpc.coredao.org')
    }
    return provider
}

const Header = () => {

    const i18n = useI18n()

    const location = useLocation()
    const [lngSwith, setLngSwith] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const menuList = [{
        logoClass: 'icon-home',
        title: 'Home Page', // '首页',
        code: '10027',
        path: '/#/home'
    }, {
        logoClass: 'icon-market',
        title: 'NFT Market',
        code: '10028',
        path: '/#/market'
    }, {
        logoClass: 'icon-bag',
        title: 'My Bag',
        code: '10029',
        path: '/#/bag'
    }, {
        logoClass: 'icon-order',
        title: 'My Order',
        code: '10030',
        path: '/#/orders'
    }]
    const { selectedLanguage, handleSetSelectedLanguage } = useContext(LanguageContext)
    const language = useLanguage()
    const provider = initWeb3Provider()
    // ,{
    //     logoClass: 'icon-order',
    //     title: '我的质押NFT',
    //     path: '/#/nft-log'
    // }

    const [balance, setBalance] = useState(0)

    const getBalance = async () => {

        const n1 = new ethers.Contract(localAddress, abi, provider)
        const res = await n1.balanceOf(provider.provider.selectedAddress)
        console.log('res', res)
        const n2 = new ethers.Contract(localAddress2, abi, provider.getSigner())
        const res2 = await n2.balanceOf(provider.provider.selectedAddress)
        // const res: any = await provider.getBalance(localAddress)
        // const res1: any = await provider.getBalance(shopAddress)
        // const res2: any = await provider.getBalance(provider.provider.selectedAddress)
        // if (res2) {
        setBalance(parseInt(res2.toString()) + parseInt(res.toString()))
        // }
    }

    useEffect(() => {
        if (window.ethereum) {
            getBalance()
        }
    }, [])

    return <div className="pledge__header con-header">
        <div className="left">
            <div className="pledge__logo" onClick={() => {
                if (location.pathname != '/pledge') {
                    window.location.href = '/#/home'
                }
            }}>
                <img src={LogoImg} alt="" />
            </div>
        </div>
        <div className="right">
            <div className="con-lang" onClick={() => {
                setLngSwith(true)
            }}>

                <span className="van-popover__wrapper">
                    <div className="lang">
                        {selectedLanguage.code == 'zh-CN'
                            ? <img className="lang__icon" src="https://token-monitor.s3.amazonaws.com/1695038244311Fui9h2AZIsAw2tffwCW9la6B6nRR" />
                            : <img className="lang__icon" src="https://token-monitor.s3.amazonaws.com/1695038294001us%402x.e9bff07f.png" />}
                        {/* <span className="zh_CN"></span> */}
                    </div>
                </span>
            </div>
            {location.pathname != '/pledge' && <span className="icon-order" onClick={() => {
                window.location.href = '/#/orders'
            }}></span>}
            {location.pathname != '/pledge' && <span className="icon-menu" onClick={() => {
                setShowMenu(true)
            }}></span>}
        </div>

        {lngSwith && <div className="menu">
            <div className="lngswith__mask" onClick={() => {
                setLngSwith(false)
            }}></div>
            <div className="lngswith" style={{
                right: location.pathname != '/pledge' ? '115px' : '15px'
            }}>
                <img className="lngswith__arrow" src="https://token-monitor.s3.amazonaws.com/1695038428052FhLF1mNGAwTJ8ag3mu85MkAUXEJK" alt="" />
                <div className="lngswith__item" onClick={() => {
                    handleSetSelectedLanguage(EN)
                    language[1](EN.code)
                    setLngSwith(false)
                }}>
                    <img className="lngswith__item__icon" src="https://token-monitor.s3.amazonaws.com/1695038294001us%402x.e9bff07f.png" alt="" />
                    <span>English</span>
                </div>
                <div className="lngswith__item" onClick={() => {
                    handleSetSelectedLanguage(ZHCN)
                    language[1](ZH_CN.code)
                    setLngSwith(false)
                }}>
                    <img className="lngswith__item__icon" src="https://token-monitor.s3.amazonaws.com/1695038244311Fui9h2AZIsAw2tffwCW9la6B6nRR" alt="" />
                    <span>中文</span>
                </div>
            </div>
        </div>}

        {showMenu && <div className="menu">
            <div className="menu__mask" onClick={() => {
                setShowMenu(false)
            }}></div>
            <div className="menu__list">
                <div className="icon-close" onClick={() => {
                    setShowMenu(false)
                }}></div>
                <div className="con-info info-box">
                    <div className="avatar"></div>
                    <div className="row">
                        <div className="ads">{provider.provider.selectedAddress.slice(0, 4) + '****' + provider.provider.selectedAddress.slice(38, 42)}</div>
                        <div className="nft"><span>{balance} NFTs</span> </div>
                    </div>
                </div>
                {menuList.map((item) => {
                    return <div key={item.title} role="button" className="van-cell van-cell--clickable" onClick={() => {
                        window.location.href = item.path // '/#/home'
                    }}>
                        <div className={item.logoClass}></div>
                        <div className="van-cell__title"><span>{i18n(item.code, item.title)}</span></div>
                        <img className="van-cell__arrow" src="https://token-monitor.s3.amazonaws.com/1695038197123Fpp5Htf6X78ixoxAhyiilIz9WdMH" alt="" />
                    </div>
                })}
            </div>
        </div>}
    </div>
}

export default Header