import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Pledge } from "."
import { ethers } from "ethers"
import CoyCell from "./components/coy-cell"
import { abi, abiSwap, shopAddress, localAddress, localAddress2 } from "./abi"
import { useI18n } from "../../i18n/i18n-react"
import './home.css'
import { initWeb3Provider } from "./components/header"

const Home = () => {

    const i18n = useI18n()
    const history = useHistory()
    const [list, setList] = useState<any[]>([])
    let provider = initWeb3Provider()


    const shopContract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())

    const getAllNft = async () => {
        const result1 = await shopContract.getAllListingNFTIDs(localAddress)
        const result2 = await shopContract.getAllListingNFTIDs(localAddress2)
        const result = [...result1, ...result2].slice(0, 6)
        console.log('ids', result, result2)
        const temp: any[] = []
        for (let i = 0; i < result.length; i++) {
            const id = result[i].toString()
            const n = await shopContract.nftList(localAddress, ethers.utils.parseUnits(id, 0))
            console.log('获取价格', n)
            let addr = localAddress
            if (i > result1.length - 1) {
                addr = localAddress2
            }
            temp.push({ id: id, price: n[1].toString(), nftAddress: addr })
        }
        setList(temp)
        console.log('result', temp)
    }

    useEffect(() => {
        if (window.ethereum) {
            getAllNft()

        }

    }, [])

    return <Pledge>
        <div className="home">
            <div className="home__banner">
                <div className="h2">{i18n('10032', "Buy or Sell")}</div>
                <div className="nft-text"></div>
                <div className="h3">{i18n('10033', 'ANGEL Nft Yes web3 NFT Market where traders and collectors earn more than')} </div>
                <div className="h4"><span>$1.3</span>{i18n('10034', "Billion Dollar Reward.")}</div>
            </div>
            <div className="home__imgs">
                <img className="home__imgs1" src="https://token-monitor.s3.amazonaws.com/1695038612843112.png" alt="" />
                <img className="home__imgs2" src="https://token-monitor.s3.amazonaws.com/1695038651657113.png" alt="" />
                <img className="home__imgs3" src="https://token-monitor.s3.amazonaws.com/1695038709385114.png" alt="" />
            </div>
            <div className="home__banner" style={{
                paddingBottom: 10
            }}>
                <div className="h2">{i18n('10032', "Buy or Sell")}</div>
                <div className="nft-text"></div>
                <div role="button" className="home__banner__items">
                    <div className="home__banner__items__item">
                        <span>{i18n('10035', "Market")}</span>
                    </div>
                    <div className="home__banner__items__item" onClick={() => {
                        history.push('/market')
                    }}>
                        <span>{i18n('10036', 'More')}</span>
                        <img style={{
                            display: 'block',
                            marginLeft: 3,
                            width: 17,
                            height: 17
                        }} src="https://token-monitor.s3.amazonaws.com/1695038197123Fpp5Htf6X78ixoxAhyiilIz9WdMH" alt="" />
                    </div>
                    {/* <i className="van-icon van-icon-arrow van-cell__right-icon"></i> */}
                </div>
            </div>
            <div className="home__list">
                {list.map((item, index) => {
                    return <CoyCell key={item.id + index} cellType={2} item={item} />
                })}
                {list.length == 0 && <div className="nftempty">
                    <img className="nftempty__icon" src="https://token-monitor.s3.amazonaws.com/1695037839853FsfQ-z2g5-LbCK92lNN5LPVwHwbF" alt="" />
                </div>}
            </div>
            <div className="home__banner" style={{
                marginTop: 50
            }}>
                <div className="h2">{i18n('10037', 'Make money by ANGEL')}</div>
                <div className="h3">{i18n('10038', 'Submit ANGEL Tokens to earn CORE Part of the daily transaction fee and more ANGEL')}</div>
                <div className="home__banner__began">{i18n('10039', 'Start making money')}</div>
            </div>
            <div className="home__imgbox">
                <img src="https://token-monitor.s3.amazonaws.com/1695038557469FkM0rYJWiM1F4QdqKe4JXTMa8gMq" alt="" />
            </div>

            <div className="home__footer">
                <div className="home__footer__header">
                    {/* <div className="home__footer__header__logo"></div> */}
                    <div className="home__footer__header__logo">
                        <img src="https://token-monitor.s3.amazonaws.com/1695037958025icon.png" alt="" />
                    </div>
                    <div className="home__footer__header__name">ANGEL</div>
                </div>
                <div className="home__footer__list">
                    {['Start Here', 'Developers', 'Getting Started', 'All Docs', 'All Guides', 'Tokenomics', 'Blog', 'API', 'Buy ANGEL', 'Audits', 'Earn ANGEL', 'Buy Bounty'].map(item => {
                        return <div key={item} className="home__footer__list__item">{item}</div>
                    })}
                </div>
                <div className="con-intro-icons">
                    <a href="https://angelswap.net/#/swap" className="item" onClick={() => {
                        history.push('/swap')
                    }}>
                        <div className="icon-logo"></div>
                        <div className="name">{i18n('nav-website', 'Official Website')}</div>
                    </a>
                    <a href="https://twitter.com/xiaoshi99867580" className="item" onClick={() => {
                        history.push('https://twitter.com/xiaoshi99867580')
                    }}>
                        <div className="icon-t"></div>
                        <div className="name">{i18n('nav-twitter', 'Twitter')}</div>
                    </a>
                    <a href="https://t.me/CoreLunaCH" className="item" onClick={() => {
                        history.push('https://t.me/CoreLunaCH')
                    }}>
                        <div className="icon-te"></div>
                        <div className="name">{i18n('nav-telegram', 'Telegram')}</div>
                    </a>
                </div>
                <div className="home__footer__text">By NFT people, for NFT people | © 2023 ANGEL I Terms</div>
            </div>
        </div>
    </Pledge>
}

export default Home