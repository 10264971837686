import React, { useEffect, useState } from "react";
import { Pledge } from ".";
import { HashRouter, useHistory } from "react-router-dom";
import { ethers } from "ethers";
import { abi, abiSwap, localAddress, shopAddress } from "./abi";
import { Contract, Provider } from 'ethers-multicall'
import "./nft-log.css"
import CoyCell from "./components/coy-cell";

const NftLog = () => {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(localAddress, abi, provider.getSigner())
    const shopContract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())    
    const ethcallProvider = new Provider(provider)
    ethcallProvider._multicallAddress = '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A'    
    const multiContract = new Contract(localAddress, abi)
    const history = useHistory()
    const [list, setList] = useState<any[]>([])

    const getData = async () => {
        const result = await shopContract.getUserAllListingNFT(provider.provider.selectedAddress, localAddress)
        console.log('aaa', result)        
        const calls = [] 
        const idList = []
        result.map(item => {
            const id = item.toString()
            idList.push(id)
            calls.push(multiContract.tokenURI(ethers.utils.parseUnits(id, 0)))
        })   
        ethcallProvider.all(calls).then(res => {
            const temp = res.map((i, index) => {
                return {
                    url: i,
                    id: idList[index]
                }
            })
            setList(temp)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return <Pledge>
        <div className="nftpage">
            <div className="nft__back" onClick={() => {
                history.goBack()
            }}>
                <img className="nft__back__icon" src="https://token-monitor.s3.amazonaws.com/1695038021279FhM6uwrBqaHBKJRZnH7zbDcuMlCt" alt="" />                
            </div>
            <div style={{
                paddingLeft: '16px'
            }}>
            {list.map(item => {
                return <CoyCell item={item} cellType={3} />
            })}
            </div>            
            {list.length == 0 && <div className="nftempty">
                <img className="nftempty__icon" src="https://token-monitor.s3.amazonaws.com/1695037839853FsfQ-z2g5-LbCK92lNN5LPVwHwbF" alt="" />
            </div>}
        </div>
    </Pledge>
}

export default NftLog