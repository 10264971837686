import { Currency, CurrencyAmount, Pair } from '@pancakeswap-libs/sdk'
import React, { useState, useContext, useCallback } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { TYPE } from '../Shared'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import { useActiveWeb3React } from '../../hooks'
import TranslatedText from '../../components/TranslatedText'
import { TranslateString } from '../../utils/translateTextHelpers'
import QuestionHelper from '../QuestionHelper'
import QuesIcon from '../../assets/images/icon_ques.png'
import { useI18n } from '../../i18n/i18n-react'
import CoyIcon from "../../assets/images/coy_logo.png"
import { Text } from 'rebass'
import Loader from '../Loader'

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  align-items: flex-end;
  // justify-content: center;
  // padding: ${({ selected }) => (selected ? '0.35rem 0.3rem 0.55rem 0.8rem' : '0.35rem 0.55rem 0.55rem 0.8rem')};
  padding: 0 6px;
  background-color: rgb(50, 70, 70);
  border-radius: 12px;
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.bg0 : theme.colors.primary1)};
  color: ${({ selected, theme }) => (selected ? theme.colors.text1 : theme.colors.white)};
  border-radius: 12px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  // padding: 0 0.5rem;

  // :focus,
  // :hover {
  //   background-color: ${({ selected, theme }) => (selected ? theme.colors.bg0 : darken(0.05, theme.colors.primary1))};
  // }
  background: rgba(0, 0, 0, 0);
  color: rgba(227, 245, 246, 1);
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.colors.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    // stroke: ${({ selected, theme }) => (selected ? theme.colors.text1 : theme.colors.white)};
    stroke: ${({ selected, theme }) => (theme.colors.white)};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.colors.bg0};
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  // border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  // border: 1px solid ${({ theme }) => theme.colors.bg0};
  // background-color: ${({ theme }) => theme.colors.bg0};
  // border-radius: ${({ hideInput }) => (hideInput ? '8px' : '12px')};
  // background-color: rgb(50, 70, 70);
  background: rgba(33, 52, 62, 1);
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  // ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0.2rem 0.25rem;')}
  // font-size:  ${({ active }) => (active ? '20px' : '16px')};
  margin: 0 0.25rem 0 0.75rem;
  font-size: 20px;

`

const StyledBalanceMax = styled.button`
  height: 28px;
  // background-color: ${({ theme }) => theme.colors.primary5};
  border: 1px solid ${({ theme }) => theme.colors.primary5};
  border-radius: 0.5rem;
  font-size: 0.875rem;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};

  width: 54px;
  height: 28px;
  margin-bottom: 14px;
  border-radius: 38px;
  border: 2px solid rgba(48, 213, 193, 1);
  background: rgb(50, 70, 70);
  box-sizing: border-box;
`

const CurrencyTips = styled.div`
  font-weight: bold;
  color: rgba(227,245,246,1);
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = TranslateString(212, 'Input'),
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases
}: CurrencyInputPanelProps) {
  const theme = useContext(ThemeContext)
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  // const selectedCurrencyBalance = useCurrencyBalance(account ? account : undefined, currency ? currency : undefined)

  const balance = useCurrencyBalance(account ? account : undefined, currency ? currency : undefined)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const i18n = useI18n()

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {/* {!hideInput && (
          <LabelRow>
            <RowBetween>
              <TYPE.body color={theme.colors.text2} fontWeight={500} fontSize={14}>
                {label}
              </TYPE.body>
              {account && (
                <TYPE.body
                  onClick={onMax}
                  color={theme.colors.text2}
                  fontWeight={500}
                  fontSize={14}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? 'Balance: ' + selectedCurrencyBalance?.toSignificant(6)
                    : ' -'}
                </TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
        )} */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <CurrencySelect
          selected={!!currency}
          className="open-currency-select-button"
          onClick={() => {
            if (!disableCurrencySelect) {
              setModalOpen(true)
            }
          }}
        >
          <Aligner>
            {pair ? (
              <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
            ) : currency ? (
              <CurrencyLogo currency={currency} size={'24px'} />
            // ) : <CurrencyLogo currency={QuesIcon} size={'24px'} />}
            ) : <img src={CoyIcon} style={{
              width: 24,
              height: 24
            }} /> }
            {pair ? (
              <StyledTokenName className="pair-name-container">
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </StyledTokenName>
            ) : (
              <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? currency.symbol.slice(0, 4) +
                    '...' +
                    currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                  : currency?.symbol) || <CurrencyTips>ANGEL</CurrencyTips> || <TranslatedText translationId={180}>Select a currency</TranslatedText>}
              </StyledTokenName>
            )}
            {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
          </Aligner>
        </CurrencySelect>
        <div>
          <RowFixed style={{ justifySelf: 'flex-end', color: 'rgb(164, 193, 202)' }}>
            {i18n('754', 'balance: ') + ' '}
            {balance ? <Balance balance={balance} /> : account ? 0 : <Loader />}
          </RowFixed>
        </div>
        </div>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                style={{
                  width: '100%'
                }}
                className="token-amount-input"
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
              {account && currency && showMaxButton && label !== 'To' && (
                <StyledBalanceMax onClick={onMax}>{i18n('max', 'MAX')}</StyledBalanceMax>
              )}
            </>
          )}
          {/* <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={'24px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || <TranslatedText translationId={180}>Select a currency</TranslatedText>}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect> */}
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
