import React, { useEffect, useRef, useState } from "react";
import { Pledge } from ".";
import { ethers } from "ethers";
import { abi, localAddress, shopAddress, abiSwap, localAddress2 } from "./abi";
import { Contract, Provider } from 'ethers-multicall'
import axios from "axios";
import CoyCell from "./components/coy-cell";
import BetterScroll from 'better-scroll'
import loadingImg from '../../assets/images/loading.gif'
import "./bag.css"
import CoyModal from "./components/coy-modal";
import { useI18n } from "../../i18n/i18n-react";

// const shopAddress = '0x17fF67Cde54360aC4Bdbf1633aA4FCA803e03008'
// const localAddress = '0xedC6CE672e334a4B2a768757576807A61D5ed908'

const Bag = () => {

    // 先定义一个provider, 里面包含了钱包信息
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const contract = new ethers.Contract('0xedC6CE672e334a4B2a768757576807A61D5ed908', abi, provider.getSigner())
    // multi fetch
    const ethcallProvider = new Provider(provider)
    ethcallProvider._multicallAddress = '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A'


    const [showModal, setShowModal] = useState(false)
    const [currentNft, setCurrentNft] = useState<any>()
    const [list, setList] = useState<any[]>([])
    const [showToast, setShowToast] = useState('')
    const i18n = useI18n()
    const [balance, setBalance] = useState(0)

    const wrapperRef = useRef(null)

    const getBalance = async () => {

        // const res: any = await provider.getBalance(localAddress)
        // const res1: any = await provider.getBalance(shopAddress)
        // const res2: any = await provider.getBalance(provider.provider.selectedAddress)
        const n = new ethers.Contract(localAddress, abi, provider.getSigner())
        const res2 = await n.balanceOf(provider.provider.selectedAddress)
        const n2 = new ethers.Contract(localAddress2, abi, provider.getSigner())
        const res3 = await n2.balanceOf(provider.provider.selectedAddress)
        if (res2 && res3) {
            setBalance(parseInt(res2.toString()) + parseInt(res3.toString()))
        }
    }

    const listRef = useRef<any[]>([])

    const getData = (tmpNftAddress) => {
        const multiContract = new Contract(tmpNftAddress, abi)
        const calls = [
            multiContract.tokensOfOwner(provider.provider.selectedAddress),
        ]
        let calls2: any[] = []
        const ids: string[] = []
        console.log('开始获取')
        ethcallProvider.all(calls).then(res => {
            console.log('结果', res)
            res[0].map(item => {
                ids.push(item.toString())
                calls2.push()
            })
            calls2 = ids.map(i => multiContract.tokenURI(ethers.utils.parseUnits(i, 0)))
            // 列表
            ethcallProvider.all(calls2).then(urls => {
                const pList: Promise<any>[] = urls.map(url => axios.get(url))
                Promise.all(pList).then(result => {
                    console.log('rrrr', result.length, list.length)
                    const rrr = result.map((item, index) => {
                        return { ...item.data, id: ids[index], nftAddress: tmpNftAddress }
                    })
                    listRef.current = [...listRef.current, ...rrr]
                    setList([...listRef.current])
                    console.log(listRef.current.length)
                })
            })
        }).catch(err => {
            console.log('error: ', err)
        })
    }

    useEffect(() => {
        getData(localAddress)
        getData(localAddress2)
        getBalance()
    }, [])

    /** 分页组件 */
    const [bsObj, setBsObj] = useState<any>(null)

    useEffect(() => {
        if (bsObj) {
            bsObj.refresh()
        }
    }, [bsObj, list])

    useEffect(() => {
        initBs()
        return () => {
            if (bsObj && bsObj.destroy) {
                bsObj.destroy()
            }
        }
    }, [wrapperRef])

    useEffect(() => {
        if (bsObj) {
            bsObj.on('pullingUp', pullingUp)
        }
    }, [bsObj])

    const pullingUp = () => {
        console.log('work')
        setTimeout(() => {
            bsObj.finishPullUp()
        }, 2000);
    }

    const initBs = () => {
        let t = new BetterScroll(wrapperRef.current, {
            scrollY: true,
            pullUpLoad: true,
            click: true
        })
        setBsObj(t)
    }
    /** 分页组件 */

    const close = (item?: any) => {
        if (item) {
            setList(old => old.filter(i => i.id != item.id))
        }
        setShowModal(false)
    }

    return <Pledge fatherStyle={{
        overflow: 'hidden'
    }}>
        <div className="bag" ref={wrapperRef}>
            <div>
                <div className="bag__header">
                    <img className="bag__header__bg" src="https://token-monitor.s3.amazonaws.com/1695037725296Fs26HDbqTc8jBPJG9asIKeJIhgx9" alt="" />
                    <img className="bag__useravatar" src="https://token-monitor.s3.amazonaws.com/1695037788046FkYquh4TiCPzOvUshOgpUljrub9S" alt="" />
                    <div className="bag__userinfo">
                        <div className="bag__username">{provider.provider.selectedAddress.slice(0, 4) + '****' + provider.provider.selectedAddress.slice(38, 42)}</div>
                        <div className="bag__usernft"> <span>{balance}</span> NFTS</div>
                    </div>
                </div>
                <div className="bag__list">
                    {list.map((item: any, index: number) => {
                        return <CoyCell key={index} item={item} clickEvent={() => {
                            setCurrentNft(item)
                            setShowModal(true)
                        }} />
                    })}
                    {list.length == 0 && <div className="bag__list__empty">
                        <img src="https://token-monitor.s3.amazonaws.com/1695037839853FsfQ-z2g5-LbCK92lNN5LPVwHwbF" alt="" />
                    </div>}
                </div>
            </div>
        </div>
        {showModal && <BagModal
            nftInfo={currentNft}
            closeEvent={close}
            submitEvent={() => {
                setShowToast(i18n('10053', 'Success'))
            }}
        />}
        {showToast && <CoyModal type="toast" text={showToast} closeEvent={() => {
            setShowToast('')
        }} />}
    </Pledge>
}

const BagModal = ({
    nftInfo,
    closeEvent,
    submitEvent
}) => {

    const i18n = useI18n()
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(nftInfo.nftAddress, abi, provider.getSigner())

    const [price, setPrice] = useState(0)
    const [isLoading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState('')

    const submit = async () => {
        if (isLoading) {
            return
        }
        setLoading(true)
        try {
            const tokenId = ethers.utils.parseUnits(nftInfo.id, 0)
            const approvedRes = await contract.getApproved(tokenId)
            if (!approvedRes) {
                throw new Error()
                return
            }
            if (approvedRes != shopAddress) {
                // 授权
                const approved = await contract.approve(shopAddress, tokenId)
                approved.wait().then(res => {
                    putaway(tokenId, price)
                })
            } else {
                putaway(tokenId, price)
            }
        } catch (err) {
            setLoading(false)
            setShowToast(i18n('10052', 'An error occurred. Please try again'))
            // setShowToast('发生错误，请重试')
            // window.alert('发生错误，请重试')            
        }
    }

    // 上架
    const putaway = async (id, price) => {
        try {
            console.log('上架价格: ', ethers.utils.parseEther(price + ''))
            console.log('上架价格数字: ', price)
            const contract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())
            const result = await contract.list(nftInfo.nftAddress, id, ethers.utils.parseEther(price + ''))
            result.wait().then(res => {
                setLoading(false)
                closeEvent(nftInfo)
                submitEvent()
                // setShowToast('上架成功')
            })
        } catch (err) {
            setLoading(false)
            setShowToast(i18n('10052', 'An error occurred. Please try again'))
        }
    }

    return <div className="ordercard">
        <div className="ordercard__mask" onClick={closeEvent}></div>
        <div className="ordercard__content">
            <div className="ordercard__header">
                <div>{i18n('10043', 'Buy')}</div>
                <img onClick={closeEvent} className="ordercard__header__close" src="https://token-monitor.s3.amazonaws.com/1695037897151Fs2OHtKlsjEqvVyN529bD80x83uE" alt="" />
            </div>
            <div className="ordercard__info">
                <img src={nftInfo.image} alt="" style={{
                    width: 68,
                    height: 68,
                    marginRight: 15
                }} />
                <div>{nftInfo.name + ' #' + nftInfo.id}</div>
            </div>
            <div className="ordercard__price">
                <div className="pledge__input">
                    <input
                        className="pledge__input__input"
                        type="number"
                        placeholder={i18n('10051', "Please Enter Price")}
                        onChange={e => {
                            setPrice(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="ordercard__bottom">
                <div className="ordercard__bottom__item" onClick={closeEvent}>{i18n('10048', 'Cancel')}</div>
                <div className="ordercard__bottom__item ordercard__bottom__submit" onClick={submit}>{i18n('10056', 'Sell')}</div>
            </div>
        </div>
        {/* {isLoading && <div className="bagloading">
            <img src={loadingImg} alt="" />
        </div>} */}
        {isLoading && <CoyModal type="loading" />}
        {showToast && <CoyModal type="toast" text={showToast} closeEvent={() => {
            setShowToast('')
        }} />}
    </div>
}

export default Bag