import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { BigNumber, ethers } from "ethers"
import { abi, abiPledge, localAddress, tokenAbi, tokenAddress } from "./abi"
import { useI18n } from "../../i18n/i18n-react"
import Header, { initWeb3Provider } from "./components/header"
import CoyModal from "./components/coy-modal"
import "./index.css"
import { GlobalPoolList } from "../App"
import copy from 'copy-to-clipboard';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

// 所有新页面背景
export const Pledge = ({ children, fatherStyle }: { children: JSX.Element, fatherStyle?: any }) => {
    return <div className="pledge">
        <Header />
        <div className="pledge__children" style={fatherStyle || {}}>
            {children}
        </div>
    </div>
}

// 挖矿页面
const PledgePageContent = () => {

    const [numCount, setNumCount] = useState<number>(0) // 挖矿数量
    // const [dayCount, setDayCount] = useState(0) // 挖矿天数
    const [showToast, setShowToast] = useState('')
    const [showLoading, setShowLoading] = useState(false)
    const [unlockTime, setUnlockTime] = useState(0)

    // const [showDaySelector, setShowDaySelector] = useState(false)
    const i18n = useI18n()
    const history = useHistory()
    const query = useQuery()

    const [tokenNameA, setTokenNameA] = useState('')
    const [tokenNameB, setTokenNameB] = useState('')
    const [stakeTokenBalance, setStakeTokenBalance] = useState(BigNumber.from('0x00'))
    const [stakeTokenDecimals, setStakeTokenDecimals] = useState(18)
    const [tokenAAddress, setTokenAAddress] = useState('')
    const [tokenBAddress, setTokenBAddress] = useState('')

    const addr = query.get('addr') || '0xCD3078F212d6bd43dc30CE2FD89Be7646A1987e5'

    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    const provider = initWeb3Provider()
    let contract = null
    if (window.ethereum) {
        contract = new ethers.Contract(addr, abiPledge, provider.getSigner())
    } else {
        contract = new ethers.Contract(addr, abiPledge, provider)
    }

    useEffect(() => {
        if (window.location.href.indexOf('amp;') > -1) {
            const u = window.location.href.replace('amp;', '')
            window.location.href = u
        }
    }, [])


    const location = useLocation()

    // 展示的信息（锁仓时间、我的收益等）    
    const [userInfo, setUserInfo] = useState({})
    const getUserInfo = async () => {
        console.log('works')
        setShowLoading(true)
        try {
            const tokenA = await contract.stakedToken()
            const tokenB = await contract.rewardToken()
            const aContract = new ethers.Contract(tokenA, tokenAbi, provider.getSigner())
            const bContract = new ethers.Contract(tokenB, tokenAbi, provider.getSigner())
            // 代币名
            console.log('质押合约', tokenA, '奖励合约', tokenB)
            const aName = await aContract.symbol()
            const bName = await bContract.symbol()
            setTokenNameA(aName)
            setTokenNameB(bName)
            setTokenAAddress(tokenA)
            setTokenBAddress(tokenB)

            const stakeBalance = await aContract.balanceOf(provider.provider.selectedAddress)
            const stakeTokenDecimals = await aContract.decimals()
            setStakeTokenBalance(stakeBalance)
            setStakeTokenDecimals(stakeTokenDecimals)

            // 精度
            const aPrecision = await aContract.decimals()
            const bPrecision = await bContract.decimals()
            // 
            const userInfo = await contract.userInfo(provider.provider.selectedAddress)
            const time = await contract.lengthOflockTime()
            const maxNum = await contract.poolLimitPerUser()

            console.log('收益', userInfo)
            const pendingReward = await contract.pendingReward(provider.provider.selectedAddress)

            setShowLoading(false)
            console.log(userInfo.amount.toString())
            setUserInfo({
                time: handleTime(parseInt(time.toString())),
                maxNum: parseInt(maxNum.toString()) / Math.pow(10, 18),
                defaultAmount: userInfo.amount,
                amount: parseInt(userInfo.amount.toString()) / Math.pow(10, aPrecision),
                award: (parseInt(pendingReward.toString()) / Math.pow(10, bPrecision)).toFixed(3)
            })
        } catch (err) {
            setShowLoading(false)
        }
    }

    // 获取解锁时间
    const getUnlockTime = async () => {
        const result = await contract.getUnlockTime(provider.provider.selectedAddress)
        console.log('time', result.toString())
        setUnlockTime(parseInt(result.toString()))
    }

    // 获取代币信息
    // const getTokenInfo = async () => {
    //     const tokenA = await contract.stakedToken()
    //     const tokenB = await contract.rewardToken()

    //     const aContract = new ethers.Contract(tokenA, tokenAbi, provider.getSigner())
    //     const bContract = new ethers.Contract(tokenB, tokenAbi, provider.getSigner())

    //     const aName = await aContract.symbol()
    //     const bName = await aContract.symbol()

    //     const aPrecision = await aContract.decimals()
    //     const bPrecision = await bContract.decimals()

    //     console.log(tokenA, tokenB, aName, aPrecision)
    // }

    useEffect(() => {
        if (window.ethereum) {
            getUserInfo()

        }
    }, [])

    useEffect(() => {
        if (window.ethereum) {
            let timer
            timer = setInterval(() => {
                getUnlockTime()
            }, 1000)
            return () => {
                clearInterval(timer)
                timer = null
            }
        }

    }, [])

    const handleTime = (my_time: number, showSec?: boolean) => {
        console.log('my_time', my_time)
        var days = my_time / 60 / 60 / 24;
        var daysRound = Math.floor(days);
        var hours = my_time / 60 / 60 - (24 * daysRound);
        var hoursRound = Math.floor(hours);
        var minutes = my_time / 60 - (24 * 60 * daysRound) - (60 * hoursRound);
        var minutesRound = Math.floor(minutes);
        var seconds = my_time - (24 * 60 * 60 * daysRound) - (60 * 60 * hoursRound) - (60 * minutesRound);
        // console.log('转换时间:', daysRound + '天', hoursRound + '时', minutesRound + '分', seconds + '秒');
        // var time = hoursRound + ':' + minutesRound + ':' + seconds        
        if (showSec) {
            return (daysRound > 0 ? (daysRound + ` ${i18n('天', 'Days')} `) : '') + (hoursRound > 0 ? (hoursRound + " " + i18n('时', 'Hours') + " ") : '') + (minutesRound > 0 ? (minutesRound + " " + i18n('分', 'Minutes')) : '') + Math.floor(seconds) + " " + i18n('秒', 'Seconds')
        }
        return (daysRound > 0 ? (daysRound + ` ${i18n('天', 'Days')} `) : '') + (hoursRound > 0 ? (hoursRound + " " + i18n('时', 'Hours') + " ") : '') + (minutesRound > 0 ? (minutesRound + " " + i18n('分', 'Minutes')) : '')
    }

    const copyLink = () => {
        let text = ''
        if (!window.ethereum) {
            text = window.location.href
        } else {

            const q = new URL(window.location.href)
            console.log(q)
            text = `${q.origin}/#/pledge?addr=${addr}&inviter=${provider.provider.selectedAddress}`

            console.log(text)
        }
        text = text.replace('amp;', '')

        copy(text)
        // if (navigator.clipboard) {
        //     // clipboard api 复制
        //     navigator.clipboard.writeText(text)
        //         .then(() => {
        //             setShowToast(i18n('10070', 'Copied To Clipboard'))
        //         });
        // } else {
        //     const input = document.createElement("input");
        //     input.value = text;
        //     document.body.appendChild(input);
        //     input.select();
        //     document.execCommand("copy");
        //     document.body.removeChild(input);
        //     // var textarea = document.createElement('input');
        //     // document.body.appendChild(textarea);
        //     // // 隐藏此输入框
        //     // textarea.style.position = 'fixed';
        //     // textarea.style.clip = 'rect(0 0 0 0)';
        //     // textarea.style.top = '10px';
        //     // // 赋值
        //     // textarea.value = text;
        //     // // 选中
        //     // textarea.select();
        //     // // 复制
        //     // document.execCommand('copy', true);
        //     // // 移除输入框
        //     // document.body.removeChild(textarea);
        //     setShowToast(i18n('10070', 'Copied To Clipboard'))

        // }
        setShowToast(i18n('10070', 'Copied To Clipboard'))

    }

    // 确认挖矿
    const submit = async () => {
        if (!numCount) {
            return
        }
        try {

            const n = ethers.utils.parseUnits(numCount + '', 18)
            if (n.gt(stakeTokenBalance)) {
                setShowToast(i18n('10073', 'Insufficient balance'))
                return
            }

            setShowLoading(true)
            // 获取授权数量
            const aContract = new ethers.Contract(tokenAAddress, tokenAbi, provider.getSigner())
            const allowance = await aContract.allowance(provider.provider.selectedAddress, addr)
            console.log('授权数量', allowance.toString(), n.toString())
            if (allowance.lt(n)) {
                const maxApprove = BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
                const nftContract = new ethers.Contract(tokenAAddress, tokenAbi, provider.getSigner())

                const res = await nftContract.approve(addr, maxApprove)
                await res.wait()
            }


            const zeroAddress = '0x0000000000000000000000000000000000000000'
            let inviter = query.get('inviter') || zeroAddress
            if (inviter.length !== 42) {
                inviter = zeroAddress
            }
            if (inviter.toLowerCase() === provider.provider.selectedAddress.toLowerCase()) {
                inviter = zeroAddress
            }
            const dres = await contract.deposit(n, inviter)
            await dres.wait()
            getUserInfo()
            setShowLoading(false)
            setShowToast(i18n('10069', 'Success'))
        } catch (err) {
            setShowToast(i18n('10058', 'failed'))
            setShowLoading(false)
            console.log('err', err)
        }
    }

    // 领取奖励
    const receiveReward = async () => {
        setShowLoading(true)
        try {
            const res = await contract.withdraw(ethers.utils.parseUnits('0', 0))
            await res.wait()
            setShowToast(i18n('10069', 'Success'))
            setShowLoading(false)
        } catch (err) {
            setShowToast(i18n('10058', 'failed'))
            setShowLoading(false)
        }
    }

    // 解除质押
    const dischargePledge = async () => {
        console.log('unlockTime', unlockTime)
        if (unlockTime > 0) {
            setShowToast(i18n('10072', 'The unlocking time has not reached'))
            return
        }
        setShowLoading(true)
        try {
            // const userInfo = await contract.userInfo(provider.provider.selectedAddress)
            const userInfo = await contract.userInfo(provider.provider.selectedAddress)
            console.log(userInfo.amount)
            const res = await contract.withdraw(userInfo.amount)
            await res.wait()
            setShowLoading(false)
            setShowToast(i18n('10069', 'Success'))
        } catch (err) {
            setShowToast(i18n('10058', 'failed'))
            setShowLoading(false)
        }
    }

    const getBannerImageUrl = () => {
        let image = ''
        GlobalPoolList.forEach(item => {
            if (item.addr.toLowerCase() === addr.toLowerCase()) {
                image = item.banner
            }
        })
        return image
    }

    return <Pledge>
        <div className="pledge__content" onClick={() => {
            // setShowDaySelector(false)
        }}>
            <div className="pledge__img">
                <img src={getBannerImageUrl()} alt="" />
            </div>
            <div className="pledge__card">
                {/* <div className="pledge__card__item">
                    <span>累计收益</span>
                    <span>{0} VPN</span>
                </div>
                <div className="pledge__card__item">
                    <span>当前收益</span>
                    <span>{0} VPN</span>
                </div>
                <div className="pledge__card__item">
                    <span>可提取数量</span>
                    <span>{0} VPN</span>
                </div> */}
                <div className="pledge__card__item">
                    <span>{i18n('10059', 'Lockup Time')}</span>
                    <span>{userInfo.time}</span>
                </div>
                {unlockTime > 0 && <div className="pledge__card__item">
                    <span>{i18n('10071', 'Unlockup Time')}</span>
                    <span>{handleTime(unlockTime, false)}</span>
                </div>}
                {userInfo.maxNum > 0 && <div className="pledge__card__item">
                    <span>{i18n("10060", "Maximum Amount Pledged")}</span>
                    <span>{userInfo.maxNum} {tokenNameA}</span>
                </div>}
                <div className="pledge__card__item">
                    <span>{i18n("10061", "Mine Amount Pledge")}</span>
                    <span>{userInfo.amount} {tokenNameA}</span>
                </div>
                <div className="pledge__card__item">
                    <span>{i18n("10062", "My Earnings")}</span>
                    <span>{userInfo.award} {tokenNameB}</span>
                </div>
            </div>
            <div className="pledge__input">
                <input className="pledge__input__input" type="number" onFocus={() => {
                    // setShowDaySelector(false)
                }} placeholder={i18n("10063", "Please Enter The Mining Quantity")} onChange={e => {
                    setNumCount(e.target.value)
                }} />
                <div className="pledge__input__coy">
                    <span>| {stakeTokenBalance.toString() / Math.pow(10, parseInt(stakeTokenDecimals))} {tokenNameA}</span>
                </div>
            </div>
            {/* <div className="pledge__input" onClick={(e) => {
                e.stopPropagation()
                setShowDaySelector(old => !old)
            }}>
                <div className="pledge__input__input">{dayCount == 0 ? '选择挖矿天数' : dayCount + '天'}</div>
                <div className="pledge__input__icon">
                    <img src="https://token-monitor.s3.amazonaws.com/1695038344322Fjr_IQ84Lpmrn-DNefOJySBzLTbC" alt="" />
                </div>

                {showDaySelector && <div className="pledge__selector">
                    {[1, 90, 180, 360].map((item) => {
                        return <div className="pledge__selector__item" onClick={(e) => {
                            e.stopPropagation()
                            setDayCount(item)
                            setShowDaySelector(false)
                        }}>{item}天</div>
                    })}
                    <img className="pledge__selector__arrowdown" src="https://token-monitor.s3.amazonaws.com/1695038386375FhmqgSwQUsyRVTYVWp4ilMKahXTC" />
                </div>}
            </div> */}
            <div className={"pledge__submit" + (numCount > 0 ? ' pledge__submit--active' : '')} onClick={submit}>{i18n('10064', 'Confirmed Mining')}</div>
            <div className="pledge__btns">
                <div className="pledge__submit pledge__submit--active" onClick={receiveReward}>{i18n('10065', 'Receive A Reward')}</div>
                <div style={{
                    width: 20
                }}></div>
                <div className="pledge__submit pledge__submit--active" onClick={dischargePledge}>{i18n('10066', 'Discharge Of Pledge')}</div>
            </div>
            <div className="pledge__btns">
                <div className="pledge__history" onClick={() => {
                    history.push('/history' + location.search)
                }}>{i18n('10067', "My Invite List")}</div>
                <div style={{
                    width: 20
                }}></div>
                <div className="pledge__history" onClick={copyLink}>{i18n('10068', 'Share')}</div>
            </div>
            {showLoading && <CoyModal
                type="loading"
                closeEvent={() => {
                    setShowLoading(false)
                }}
            />}
            {showToast && <CoyModal
                type="toast"
                text={showToast}
                closeEvent={() => {
                    setShowToast('')
                }}
            />}
        </div>
    </Pledge>
}

export default PledgePageContent