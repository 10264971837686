import React, { useEffect } from 'react'
import loadingImg from '../../../assets/images/loading.gif'
import "./coy-modal.css"

const CoyModal = ({
    type,
    text,
    closeEvent
}: {
    type: string // loading toast
    text?: string
    closeEvent?: any
}) => {
    useEffect(() => {
        if (type == 'toast') {
            setTimeout(() => {
                closeEvent()
            }, 1000);
        }        
    }, [type])
    if (type == 'loading') {
        return <div className="coymodal">
            <img src={loadingImg} alt="" />
        </div>    
    }
    return <div className="coymodal">
        <div className='coymodal__text'>{text}</div>
    </div>
}

export default CoyModal