import React, { useEffect, useRef, useState } from "react"
import CoyCell from "./components/coy-cell"
import { Pledge } from "."
import { ethers } from "ethers"
import { abiSwap, shopAddress, localAddress, abi, localAddress2 } from "./abi"
import "./market.css"
import "./components/header.css"
import { useI18n } from "../../i18n/i18n-react"
import { Contract, Provider } from "ethers-multicall"

const initEthProvider = (provider) => {
    provider._multicallAddress = '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A'
    return provider
}

const Market = () => {

    const i18n = useI18n()
    const [list, setList] = useState<any[]>([])
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const contract = new ethers.Contract(localAddress, abi, provider.getSigner())
    const contract2 = new ethers.Contract(localAddress2, abi, provider.getSigner())
    const shopContract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())
    const [balance, setBalance] = useState(0)

    const listRef = useRef<any[]>([])
    const getAllNft = async (tmpNftAddress) => {
        const result = await shopContract.getAllListingNFTIDs(tmpNftAddress)
        // const result2 = await shopContract.getAllListingNFTIDs(localAddress2)
        // const result = [...result1, ...result2]
        console.log('ids', result)
        let ethProvider = new Provider(provider)
        ethProvider = initEthProvider(ethProvider)
        const temp: any[] = []
        let calls: any[] = []
        const sp = new Contract(shopAddress, abiSwap)
        for (let i = 0; i < result.length; i++) {
            const id = result[i].toString()
            // const n = await shopContract.nftList(localAddress, ethers.utils.parseUnits(id, 0))
            calls.push(sp.nftList(tmpNftAddress, ethers.utils.parseUnits(id, 0)))
            // console.log('bb', n)
            // temp.push({ id: id, price: n[1].toString() })
        }

        const res = await ethProvider.all(calls)
        console.log('res', res)
        for (let i = 0; i < res.length; i++) {
            const id = result[i].toString()
            temp.push({ id: id, price: res[i][1].toString(), nftAddress: tmpNftAddress })
        }

        listRef.current = [...listRef.current, ...temp]
        setList([...listRef.current])
        // setList([...list, ...temp])
        console.log('result', temp)
    }

    const getBalance = async () => {
        const res = await contract.balanceOf(provider.provider.selectedAddress)
        const res2 = await contract2.balanceOf(provider.provider.selectedAddress)
        setBalance(parseInt(res.toString()) + parseInt(res2.toString()))
        // const res2: any = await provider.getBalance(provider.provider.selectedAddress)
        // if (res2 && res) {
        // setBalance(parseInt(res.toString()) + res2 / Math.pow(10, 18))
        // }
    }

    const getAddress = () => {
        const t = provider.provider.selectedAddress
        return t.slice(0, 4) + '****' + t.slice(38, 42)
    }

    useEffect(() => {
        getAllNft(localAddress)
        getAllNft(localAddress2)
        getBalance()
    }, [])

    return <Pledge>
        <div className="market">
            <img className="market__bg" src="https://token-monitor.s3.amazonaws.com/1695038473710FiFvpjeqb-COYf0JczNFq2g0dYZc" alt="" />
            <div className="market__header">
                <div className="con-info info-box" style={{
                    margin: 0
                }}>
                    <div className="avatar"></div>
                    <div className="row">
                        <div className="ads">{getAddress()}</div>
                        <div className="nft"><span>{balance} NFTs</span> </div>
                    </div>
                </div>
            </div>
            <div className="market__tips">{i18n('10042', 'There are 550 ANGEL equity cards in total. Holding it will enjoy high dividends, and it will represent the consensus of ANGEL.')}</div>
            <div className="market__list">
                {list.map(item => {
                    return <CoyCell key={item.id} cellType={2} item={item} />
                })}
            </div>
        </div>
    </Pledge>
}

export default Market