import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { NavLink, Link as HistoryLink } from 'react-router-dom'

import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'
import TranslatedText from '../TranslatedText'
import { useI18n } from '../../i18n/i18n-react'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  // justify-content: center;
  // justify-content: space-evenly;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;  
  height: 3rem;
  margin-right: 20px;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text3};
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding: 0 4px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.lightText};
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.lightText};
    // color: ${({ theme }) => darken(0.1, theme.colors.text1)};
  }
`

const StyledNavLink1 = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;  
  height: 3rem;
  margin-right: 20px;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text3};
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding: 0 4px;

  // &.${activeClassName} {
  //   border-radius: 12px;
  //   font-weight: 500;
  //   color: ${({ theme }) => theme.colors.lightText};
  // }

  :hover,
  :focus {
    // color: ${({ theme }) => theme.colors.lightText};
    // color: ${({ theme }) => darken(0.1, theme.colors.text1)};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text1};
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const [path, setPath] = useState<any>(window.location.hash)
  // useEffect(() => {
  //   console.log('sss', window.location)
  //   setPath(window.location.hash)
  // }, [])
  const i18n = useI18n()
  return (
    <Tabs style={{ marginBottom: '0px' }}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} onClick={() => {
        setPath('#/swap')
      }} isActive={() => path === '#/swap'}>
        <div>{i18n('268', 'Swap')}</div>
        {path === '#/swap' && <div
          style={{
            position: 'absolute',
            bottom: '0px',
            height: '4px',
            width: '2em',
            background: 'rgb(31, 255, 255)',
            borderRadius: '2px 2px 0px 0px'
          }}
        ></div>}
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} onClick={() => {
        setPath('#/pool')
      }} isActive={() => /#\/remove/.test(path) || /#\/add\/ETH\//.test(path) || path === '#/pool' || path === '#/find'}>
        {i18n('270', "Pool")}
        {(/#\/remove/.test(path) || /#\/add\/ETH\//.test(path) || path == '#/pool' || path == '#/find') && <div
          style={{
            position: 'absolute',
            bottom: '0px',
            height: '4px',
            width: '2em',
            background: 'rgb(31, 255, 255)',
            borderRadius: '2px 2px 0px 0px'
          }}
        ></div>}
      </StyledNavLink>
      {/* <StyledNavLink1>
        <TranslatedText translationId={184}>Gnosis Safe</TranslatedText>        
      </StyledNavLink1>
      <StyledNavLink1>
        {i18n('bridge', "Bridge")}
      </StyledNavLink1> */}
    </Tabs>
  )
}

export function FindPoolTabs() {
  const i18n = useI18n()

  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{i18n("Import Pool", "Import Pool")}</ActiveText>
        <QuestionHelper text={i18n("Usethistool", "Use this tool to find pairs that don't automatically appear in the interface.")} />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  const i18n = useI18n()

  return (
    <Tabs>
      <RowBetween style={{
        padding: '1rem',
        borderBottom: '1x solid rgb(48, 213, 193)'
      }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        {/* <ActiveText>{adding ? 'Add' : 'Remove'} Liquidity</ActiveText> */}
        <ActiveText>{adding ? i18n('AddLiquidity', 'Add Liquidity') : i18n('RemoveLiquidity', 'Remove Liquidity')}</ActiveText>
        <QuestionHelper
          text={
            adding
              ? i18n("Whenyou", 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.')
              : i18n("Removepooltokens", 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.')
          }
        />
      </RowBetween>
    </Tabs>
  )
}
