import React, { useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { BigNumber, ethers } from "ethers"
import { abi, abiPledge, localAddress, tokenAbi, tokenAddress } from "./abi"
import { useI18n } from "../../i18n/i18n-react"
import BetterScroll from 'better-scroll'
import CoyModal from "./components/coy-modal"
// import { Button, Table } from "antd"
// import { Table, Divider, Tag } from 'antd';
import Table from 'antd/es/table'; // 加载 JS
import 'antd/es/table/style/css'; // 加载 CSS
import "./nft-log.css"
import "./history.css"
import { Contract, Provider } from "ethers-multicall"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const History = () => {

    const i18n = useI18n()
    const history = useHistory()
    const query = useQuery()
    const addr = query.get('addr') || '0xCD3078F212d6bd43dc30CE2FD89Be7646A1987e5'
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(addr, abiPledge, provider.getSigner())

    // 分页
    const [list, setList] = useState<any[]>([])
    const [list2, setList2] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    // 列数
    const [tableCount, setTableCount] = useState(0)
    const [tableCount2, setTableCount2] = useState(0)
    const [totalInvite, setTotalInvite] = useState(0)
    const [totalUser, setTotalUser] = useState(0) // 质押用户数
    const [totalToken, setTotalToken] = useState(0) // 质押总量
    const [isOwner, setIsOwner] = useState(false)

    const selectedAddress = provider?.provider?.selectedAddress
    // const selectedAddress = '0x051946496787177358F5FFd711B8aFC50c37f007'


    const getTableInfo = async () => {
        const totalToken = await contract.getTotalStakeAmount()
        setTotalToken(totalToken.toString())
        // 获取管理
        console.log('selectedAddress', selectedAddress)
        const owner = await contract.whitelistedUsers(selectedAddress)
        if (owner) {
            setIsOwner(true)
        }
        // if (owner.toLowerCase() === selectedAddress.toLowerCase()) {
        //     setIsOwner(true)
        // }
    }

    const [precisionA, setPrecisionA] = useState(0)
    const [precisionB, setPrecisionB] = useState(0)
    const [tokenNameA, setTokenNameA] = useState('')
    const [tokenNameB, setTokenNameB] = useState('')
    const [totalInviteUser, setTotalInviteUser] = useState(0) // 质押用户数

    const getTokenName = async () => {
        let pA: any = precisionA
        let pB: any = precisionB
        let tA: any = tokenNameA
        let tB: any = tokenNameB
        if (pA && pB && tA && tB) {
            return {
                precisionA: pA,
                precisionB: pB,
                tokenNameA: tA,
                tokenNameB: tB
            }
        }
        const tokenA = await contract.stakedToken()
        const tokenB = await contract.rewardToken()
        const aContract = new ethers.Contract(tokenA, tokenAbi, provider.getSigner())
        const bContract = new ethers.Contract(tokenB, tokenAbi, provider.getSigner())
        // 代币名
        const aName = await aContract.symbol()
        const bName = await bContract.symbol()
        tA = aName
        tB = bName
        setTokenNameA(aName)
        setTokenNameB(bName)
        // 精度        
        const aPrecision = await aContract.decimals()
        const bPrecision = await bContract.decimals()
        pA = parseInt(aPrecision.toString())
        pB = parseInt(bPrecision.toString())
        console.log('tatb', tA, tB, pA, pB)
        setPrecisionA(aPrecision)
        setPrecisionB(bPrecision)
        return {
            precisionA: pA,
            precisionB: pB,
            tokenNameA: tA,
            tokenNameB: tB
        }
    }

    const getPagData = async (page: number) => {
        setLoading(true)

        // 邀请总数
        let inviteCount = totalInvite
        if (inviteCount <= 0) {
            let res = await contract.inviterListsForUserTotal(selectedAddress)
            let resInt = parseInt(res.toString())
            setTableCount(Math.ceil(resInt / 30))
            setTotalInvite(resInt)
        }

        // 精度
        const {
            precisionA: pA,
            precisionB: pB,
            tokenNameA: tA,
            tokenNameB: tB
        } = await getTokenName()


        try {
            const perPage = 30
            console.log('current page', page)
            const total = await contract.inviterListsForUserTotal(selectedAddress)

            const pool = new Contract(addr, abiPledge)
            let ethcallProvider = new Provider(provider)
            ethcallProvider._multicallAddress = '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A'

            let calls2 = []
            for (let i = total - ((page - 1) * perPage) - 1; i >= 0; i--) {
                calls2.push(pool.inviterListsForUserPage(selectedAddress, ethers.utils.parseUnits(i + '', 0)))
                if (calls2.length >= perPage) break
            }

            console.log('total', total.toString(), calls2)
            setTotalInviteUser(total.toString())
            // const res = await contract.inviterListsForUserPage(selectedAddress, ethers.utils.parseUnits(page + '', 0))
            const res = await ethcallProvider.all(calls2)
            setList(res.map((i, index) => {
                return {
                    key: i.userAddr + index,
                    userAddr: handleAddr(i.userAddr),
                    invitor: handleAddr(i.invitor),
                    stakeAmount: handleRewardCount(i.stakeAmount.toString(), pA) + ' ' + tA,
                    rewardAmount: handleRewardCount(i.rewardAmount.toString(), pB) + ' ' + tB,
                    hasBeenClaimdReward: handleRewardCount(i.hasBeenClaimdReward.toString(), pB) + ' ' + tB
                }
            }))
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    const getPagData2 = async (page: number) => {
        setLoading(true)

        // 用户数
        let userCount = parseInt(totalUser + '')
        if (userCount <= 0) {
            let temp = await contract.getUsersCount()
            let tempStr = temp.toString()
            userCount = parseInt(tempStr)
            setTotalUser(tempStr)
            setTableCount2(Math.ceil(parseInt(tempStr) / 30))
        }

        // 精度
        const {
            precisionA: pA,
            precisionB: pB,
            tokenNameA: tA,
            tokenNameB: tB
        } = await getTokenName()

        try {
            // 获取方法
            const ethcallProvider = new Provider(provider)
            ethcallProvider._multicallAddress = '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A'
            const multiContract = new Contract(addr, abiPledge)
            const perPage = 30
            // 获取
            const calls: any[] = []
            for (let i = userCount - ((page - 1) * perPage) - 1; i >= 0; i--) {
                calls.push(multiContract.getUserById(ethers.utils.parseUnits(i + '', 0)))
                if (calls.length >= perPage) break
            }
            const callList = await ethcallProvider.all(calls)
            setLoading(false)
            setList2(callList.map((item, index) => {
                return {
                    key: item.userAddr + index,
                    userAddr: handleAddr(item.userAddr),
                    invitor: handleAddr(item.invitor),
                    stakeAmount: handleRewardCount(item.stakeAmount.toString(), pA) + ' ' + tA,
                    rewardAmount: handleRewardCount(item.rewardAmount.toString(), pB) + ' ' + tB,
                    hasBeenClaimdReward: handleRewardCount(item.hasBeenClaimdReward.toString(), pB) + ' ' + tB
                }
            }))
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getPagData(1)
        getPagData2(1)
        getTableInfo()
    }, [])

    const handleAddr = (value) => {
        if (value && value.length > 40) {
            if (value === '0x0000000000000000000000000000000000000000') {
                return ''
            }
            return value.slice(0, 4) + '****' + value.slice(38, 42)
        }
        return ''
    }

    const handleRewardCount = (value, precision) => {
        let temp = value
        // console.log('######## function handle reward ########')
        // console.log(value)
        if (typeof value == 'string') {
            temp = parseInt(value)
        }
        // console.log(temp, 10 ** precision)
        // console.log('######## function handle reward end ########')
        return temp / (10 ** precision)
    }

    const columns = [
        {
            title: i18n('地址', 'Address'),
            dataIndex: 'userAddr',
            key: 'userAddr',
        },
        {
            title: i18n('已质押', 'Staked Amount'),
            dataIndex: 'stakeAmount',
            key: 'stakeAmount',
        },
        {
            title: i18n('总奖励', "Reward"),
            dataIndex: 'rewardAmount',
            key: 'rewardAmount',
        },
        {
            title: i18n('已领取奖励', 'Claimed Reward'),
            dataIndex: 'hasBeenClaimdReward',
            key: 'hasBeenClaimdReward',
        },
        {
            title: i18n('邀请人', 'Invitor'),
            dataIndex: 'invitor',
            key: 'invitor',
        }
    ]

    return <div className="history">
        <div className="nft__back" onClick={() => {
            history.goBack()
        }}>
            <img className="nft__back__icon" src="https://token-monitor.s3.amazonaws.com/1695038021279FhM6uwrBqaHBKJRZnH7zbDcuMlCt" alt="" />
        </div>
        <div className="history__list">
            <div className="admin__header">
                <div style={{ color: '#ffffff' }} className="admin__title">
                    {i18n('我的邀请列表', 'Invite History')}
                </div>
                <div style={{ color: '#ffffff' }} className="admin__subtitle"> {i18n('已邀请用户数', 'Invited Users Count')} : {totalInviteUser} </div>
            </div>
            <Table
                dataSource={list}
                columns={columns}
                size="middle"
                pagination={{
                    pageSize: 30,
                    // total: tableCount ? tableCount : undefined
                    total: totalInvite
                }}
                onChange={(e) => {
                    getPagData(e.current || 0)
                }}
            />


            {isOwner && <div className="admin">
                <div className="admin__header">
                    <div className="admin__title">
                        {i18n('管理员数据面板', 'Admin Panel')}
                    </div>
                    <div className="admin__subtitle"> {i18n('质押用户数', 'Staking Users Count')} ：{totalUser} &nbsp;&nbsp;&nbsp;&nbsp; {i18n('质押总量', 'Total Staked Amount')} ：{parseInt((totalToken + '') || '') / (10 ** precisionA)} {tokenNameB}</div>
                </div>
                <Table
                    dataSource={list2}
                    columns={columns}
                    size="middle"
                    pagination={{
                        pageSize: 30,
                        // total: tableCount2 ? tableCount2 : undefined
                        total: totalUser
                    }}
                    onChange={(e) => {
                        console.log('切换页面', e.current)
                        getPagData2(e.current || 0)
                    }}
                />
            </div>}
            {/* <div>
                    {list.map((item, index) => {
                        return <div key={item.userAddr + index} className="historyuser">
                            <div className="historyuser__item">
                                <div className="historyuser__title">用户地址</div>
                                <div className="historyuser__value">{handleAddr(item.userAddr)}</div>
                            </div>
                            <div className="historyuser__item">
                                <div className="historyuser__title">邀请地址</div>
                                <div className="historyuser__value">{handleAddr(item.invitor)}</div>
                            </div>
                            <div className="historyuser__item">
                                <div className="historyuser__title">质押数量</div>
                                <div className="historyuser__value">{item.stakeAmount} {tokenNameA}</div>
                            </div>
                            <div className="historyuser__item">
                                <div className="historyuser__title">奖励数量</div>
                                <div className="historyuser__value">{item.rewardAmount} {tokenNameB}</div>
                            </div>
                            <div className="historyuser__item">
                                <div className="historyuser__title">已领取奖励</div>
                                <div className="historyuser__value">{handleRewardCount(item.hasBeenClaimdReward)} {tokenNameB}</div>
                            </div>
                        </div>
                    })}
                    {list.length == 0 && <div className="nftempty">
                        <img className="nftempty__icon" src="https://token-monitor.s3.amazonaws.com/1695037839853FsfQ-z2g5-LbCK92lNN5LPVwHwbF" alt="" />
                    </div>}
                </div> */}
        </div>
        {loading && <CoyModal type="loading" />}
    </div>
}

export default History