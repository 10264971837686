import React, { useEffect, useRef, useState } from "react";
import { Pledge } from ".";
import { ethers } from "ethers";
import { abi, abiSwap, localAddress, localAddress2, shopAddress } from "./abi"
import { Contract, Provider } from 'ethers-multicall'
import CoyCell from "./components/coy-cell"
import axios from "axios";
import loadingImg from '../../assets/images/loading.gif'
import "./order.css"
import { useI18n } from "../../i18n/i18n-react";
import CoyModal from "./components/coy-modal";


const Order = () => {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const contract = new ethers.Contract(localAddress, abi, provider.getSigner())
    const shopContract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())

    const ethcallProvider = new Provider(provider)
    ethcallProvider._multicallAddress = '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A'
    // const multiContract = new Contract(localAddress, abi)

    const i18n = useI18n()
    const [list, setList] = useState<any>([])
    const [currentTab, setCurrentTab] = useState(0)
    const [currentNft, setCurrentNft] = useState()
    const [showRevokeModal, setShowRevokeModal] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState('')

    const list1Ref = useRef<any[]>([])
    const getData = async (nftAddress) => {
        // let acc = await window.ethereum.request({ method: 'eth_requestAccounts' })    
        const multiContract = new Contract(nftAddress, abi)
        const res = await shopContract.getUserOrder(provider.provider.selectedAddress)
        const idList: string[] = []
        const calls: any[] = []
        console.log('listss', res)
        if (res && res.length >= 2) {
            res[0].map(item => {
                const temp = item.nftid.toString()
                // if (!idList.includes(temp)) {
                console.log('price', item.price.toString(), item, 'iiiiitem')
                idList.push({
                    ...item,
                    id: temp,
                    price: item.price.toString(),
                })
                calls.push(multiContract.tokenURI(ethers.utils.parseUnits(temp, 0)))
                // }
            })
            res[1].map((i, index) => {
                const temp = i.toString()
                if (temp != '1') {
                    idList.splice(index, 1)
                    calls.splice(index, 1)
                }
            })
        }
        ethcallProvider.all(calls).then(res => {
            const temp = res.map((i, index) => {
                let t: any = idList[index]
                return {
                    ...t,
                    url: i,
                    // id: idList[index]
                    nftAddress

                }
            })
            list1Ref.current = [...list1Ref.current, ...temp]
            console.log('listttt', temp)

            // 去重复
            const obj = {}
            list1Ref.current = list1Ref.current.reduce((cur, next) => {
                if (!obj[next.id + '-' + next.nftAddress]) {
                    obj[next.id + '-' + next.nftAddress] = true
                    cur.push(next)
                }
                return cur;
            }, [])

            setList([...list1Ref.current])
        })
    }

    const list2Ref = useRef<any[]>([])

    const getData2 = async (nftAddress) => {
        const result = await shopContract.getUserAllListingNFT(provider.provider.selectedAddress, nftAddress)
        let calls = []
        const idList = []
        const multiContract = new Contract(nftAddress, abi)
        result.map(item => {
            const id = item.toString()
            idList.push(id)
            calls.push(multiContract.tokenURI(ethers.utils.parseUnits(id, 0)))
        })
        ethcallProvider.all(calls).then(res => {
            const temp = res.map((i, index) => {
                return {
                    url: i,
                    id: idList[index],
                    nftAddress
                }
            })
            list2Ref.current = [...list2Ref.current, ...temp]
            console.log('listttt', temp)
            // 去重复
            const obj = {}
            list2Ref.current = list2Ref.current.reduce((cur, next) => {
                if (!obj[next.id + '-' + next.nftAddress]) {
                    obj[next.id + '-' + next.nftAddress] = true
                    cur.push(next)
                }
                return cur;
            }, [])
            setList([...list2Ref.current])
        })
    }

    useEffect(() => {
        if (currentTab == 0) {
            getData2(localAddress)
            getData2(localAddress2)
        } else {
            getData(localAddress)
            getData(localAddress2)
        }
    }, [currentTab])

    const submit = async (item) => {
        // 撤单： 卖家取消挂单
        try {
            setLoading(true)
            const res = await shopContract.revoke(item.nftAddress, ethers.utils.parseUnits(currentNft.id, 0))
            res.wait().then(res => {
                setLoading(false)
                setShowRevokeModal(false)
                setShowToast(i18n('10057', 'Success'))
                setList(i => i.filter(i2 => i2.id != currentNft.id))
            })
        } catch (rr) {
            setLoading(false)
            setShowToast(i18n('10058', "failed"))
        }
    }

    return <Pledge>
        <div className="orderpage">
            <div className="orderpage__tab">
                <div className={"orderpage__tab__item" + (currentTab == 0 ? ' orderpage__tab__item--active' : '')} onClick={() => {
                    setCurrentTab(0)
                }}>{i18n('10040', 'On shelves')}</div>
                <div className={"orderpage__tab__item" + (currentTab == 1 ? ' orderpage__tab__item--active' : '')} onClick={() => {
                    setCurrentTab(1)
                }}>{i18n('10041', 'Have purchased')}</div>
            </div>
            <div className="orderpage__list">
                {list.map((item, index) => {
                    if (currentTab == 0) {
                        return <CoyCell
                            key={item.id + index}
                            cellType={3}
                            item={item}
                            clickEvent={() => {
                                setCurrentNft(item)
                                setShowRevokeModal(true)
                            }}
                        />
                    } else {
                        return <CoyCell
                            key={item.id + index}
                            cellType={3}
                            item={item}
                        />
                    }
                })}
            </div>
            {list.length == 0 && <div className="nftempty">
                <img className="nftempty__icon" src="https://token-monitor.s3.amazonaws.com/1695037839853FsfQ-z2g5-LbCK92lNN5LPVwHwbF" alt="" />
            </div>}
        </div>
        {showRevokeModal && <RevokeModal
            nftInfo={currentNft}
            submitEvent={() => {
                submit(currentNft)
            }}
            closeEvent={() => {
                setShowRevokeModal(false)
            }}
        />}
        {isLoading && <div className="bagloading">
            <img src={loadingImg} alt="" />
        </div>}
        {showToast && <CoyModal
            type="toast"
            text={showToast}
            closeEvent={() => {
                setShowToast('')
            }}
        />}
    </Pledge>
}

const RevokeModal = ({
    nftInfo,
    closeEvent = () => { },
    submitEvent
}: {
    nftInfo?: any,
    closeEvent?: () => void
    submitEvent: any
}) => {

    const i18n = useI18n()
    const [nft, setNft] = useState()

    useEffect(() => {
        if (nftInfo.url) {
            axios.get(nftInfo.url).then(res => {
                setNft(res.data)
            })
        }
    }, [nftInfo])

    return <div className="ordercard">
        <div className="ordercard__mask" onClick={closeEvent}></div>
        <div className="ordercard__content">
            <div className="ordercard__header">
                <div>{i18n('10055', 'Cancel Pending Order')}</div>
                <img onClick={closeEvent} className="ordercard__header__close" src="https://token-monitor.s3.amazonaws.com/1695037897151Fs2OHtKlsjEqvVyN529bD80x83uE" alt="" />
            </div>
            <div className="ordercard__info">
                <img src={nft && nft.image} alt="" style={{
                    width: 68,
                    height: 68,
                    marginRight: 15
                }} />
                <div>{(nft && nft.name) + ' #' + nftInfo.id}</div>
            </div>
            <div className="ordercard__bottom">
                <div className="ordercard__bottom__item" onClick={closeEvent}>{i18n('10048', 'Cancel')}</div>
                <div className="ordercard__bottom__item ordercard__bottom__submit" onClick={submitEvent}>{i18n('10055', "Cancel Pending")}</div>
            </div>
        </div>
    </div>
}

export default Order