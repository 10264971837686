import React from 'react'
import styled from 'styled-components'
import { NavLink, useHistory } from 'react-router-dom'

import { useI18n } from '../../i18n/i18n-react'

import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down.svg'
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg'
import { NETWORK_CHAIN_ID } from '../../connectors'

const navItems = [
  // {
  //   label: 'Exchange',
  //   i18nKey: 'nav-exchange',
  //   to: '/',
  //   chainId: [128, 66, 56, 137]
  // },
  // {
  //   label: 'Mining',
  //   i18nKey: 'nav-mining',
  //   chainId: [56],
  //   children: [
  //     {
  //       label: 'Liquidity Mining',
  //       i18nKey: 'nav-liquidity-mining',
  //       to: '/farm',
  //       chainId: [56],
  //     },
  //     {
  //       label: 'Staking Mining',
  //       i18nKey: 'nav-staking-mining',
  //       to: '/staking',
  //       chainId: [56],
  //     },
  //     // {
  //     //   label: 'xPIPI Pool',
  //     //   i18nKey: 'nav-xpipi-pool',
  //     //   to: 'https://www.alchemistswap.finance/xpipi',
  //     //   chainId: [128, 66, 56, 137],
  //     // },
  //     // {
  //     //   label: 'LockDrop',
  //     //   i18nKey: 'nav-lockDrop',
  //     //   to: 'https://www.alchemistswap.finance/auto',
  //     //   chainId: [128],
  //     // }
  //   ]
  // },
  // {
  //   label: 'Yield',
  //   i18nKey: 'nav-yield',
  //   chainId: [66],
  //   target: '_blank',
  //   to: 'https://yield.pippi.finance/',
  // },
  // {
  //   label: 'Tool',
  //   i18nKey: 'nav-tool',
  //   chainId: [128],
  //   children: [
  //     {
  //       label: 'Voting',
  //       i18nKey: 'nav-voting',
  //       to: 'https://voting.pippi.finance',
  //       target: '_blank',
  //       chainId: [128],
  //     },
  //     {
  //       label: 'Analytics',
  //       i18nKey: 'nav-analytics',
  //       to: 'https://info.pippi.finance',
  //       target: '_blank',
  //       chainId: [128],
  //     },
  //     {
  //       label: 'NFT',
  //       i18nKey: '',
  //       to: 'https://www.alchemistswap.finance/nft',
  //       chainId: [128],
  //     }
  //   ]
  // },
  // {
  //   label: 'More',
  //   i18nKey: 'nav-more',
  //   chainId: [128, 66, 56, 137],
  //   children: [
  //     {
  //       label: 'Website',
  //       i18nKey: 'nav-website',
  //       to: 'https://twitter.com/alchemistchurch?s=21',
  //       target: '_blank',
  //       chainId: [128, 66, 56, 137],
  //     },
  //     {
  //       label: 'Twitter',
  //       i18nKey: 'nav-twitter',
  //       to: 'https://twitter.com/alchemistchurch?s=21',
  //       target: '_blank',
  //       chainId: [128, 66, 56, 137],
  //     },
  //     {
  //       label: 'Telegram',
  //       i18nKey: 'nav-telegram',
  //       to: 'https://t.me/Alchemistchurch',
  //       target: '_blank',
  //       chainId: [128, 66, 56, 137],
  //     }
  //     // {
  //     //   label: 'Docs',
  //     //   i18nKey: 'nav-docs',
  //     //   to: 'https://docs.pippi.finance/',
  //     //   target: '_blank',
  //     //   chainId: [128, 66, 56, 137],
  //     // },
  //     // {
  //     //   label: 'Code',
  //     //   i18nKey: 'nav-code',
  //     //   to: 'https://github.com/Alchemist-Labs',
  //     //   target: '_blank',
  //     //   chainId: [128, 66, 56, 137],
  //     // },
  //     // {
  //     //   label: 'Blog',
  //     //   i18nKey: 'nav-blog',
  //     //   to: 'https://medium.com/@Alchemistswap',
  //     //   target: '_blank',
  //     //   chainId: [128, 66, 56, 137],
  //     // },
  //     // {
  //     //   label: 'Annoucement',
  //     //   i18nKey: 'nav-annoucement',
  //     //   to: 'https://twitter.com/pippiAlchemistswap',
  //     //   target: '_blank',
  //     //   chainId: [128, 66, 56, 137],
  //     // },
  //     // {
  //     //   label: 'Audit',
  //     //   i18nKey: 'nav-audit',
  //     //   to: 'https://pippi.finance/static/media/Pippi%20Alchemist_audit.1cd63cbb.pdf',
  //     //   target: '_blank',
  //     //   chainId: [128, 66, 56, 137],
  //     // }
  //   ]
  // },
  // {
  //   label: 'Cross Chain',
  //   i18nKey: 'nav-cross-chain',
  //   chainId: [128, 66, 56, 137],
  //   children: [
  //     {
  //       label: 'TransitSwap',
  //       i18nKey: 'nav-transit',
  //       to: 'https://swap.transit.finance/?utm_source=tokenpocket#/',
  //       target: '_blank',
  //       chainId: [128, 66, 56, 137],
  //     },
  //     {
  //       label: 'AnySwap',
  //       i18nKey: 'nav-anyswap',
  //       to: 'https://anyswap.exchange/?utm_source=tokenpocket#/router',
  //       target: '_blank',
  //       chainId: [128, 66, 56, 137],
  //     }
  //   ]
  // }
]

function NavItem({ label, i18nKey, to, target, children }: any) {
  const i18n = useI18n()
  const history = useHistory()

  if (children) {
    return (
      <div className="nav-item-container">
        <div className="nav-item" style={{ color: 'rgb(227, 245, 246)' }}>
          {i18n(i18nKey, label)}
          <ArrowDown className="icon icon-arrow-down" />
          <ArrowUp className="icon icon-arrow-up" />
        </div>
        <div className="nav-item-list">
          {children.filter(item => item?.chainId.includes(NETWORK_CHAIN_ID)).map((item, index) => {
            const handleClick = () => {
              if (item.to.startsWith('http')) {
                if (item.target === '_blank') {
                  window.open(item.to, '_blank')
                } else {
                  window.location.href = item.to
                }
              } else {
                history.push(item.to)
              }
            }
            return (
              <div className="nav-item-list-item" onClick={handleClick} key={index}>
                {i18n(item.i18nKey, item.label)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  if (to.startsWith('http')) {
    return (
      <StyledAbsoluteLink className="nav-item" target={target} href={to} style={{ color: 'rgb(227, 245, 246)' }}>
        {i18n(i18nKey, label)}
      </StyledAbsoluteLink>
    )
  }

  return (
    <StyledLink className="nav-item" exact activeClassName="active" to={to} style={{ color: 'rgb(227, 245, 246)' }}>
      {i18n(i18nKey, label)}
    </StyledLink>
  )
}

const Nav: React.FC = () => {
  return (
    <>
      <StyledNav>
        {navItems
          .filter(item => item?.chainId.includes(NETWORK_CHAIN_ID))
          .map((item, index) => (
            <NavItem {...item} key={index} />
          ))}
      </StyledNav>
    </>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  @media (max-width: 550px) {
    display: none;
  }

  .nav-item {
    position: relative;
    font-size: 14px;
    color: #495259;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-right: 26px;
    &:hover {
      color: #9faab9;
    }

    .nav-item + .nav-item {
      margin-left: 10px;
    }

    .icon {
      margin-left: 5px;
      display: inline-block;
      width: 12px;
      height: 12px;
      top: 0px;
      right: -10px;
    }

    .icon-arrow-down {
      display: block;
    }
    .icon-arrow-up {
      display: none;
    }

    &:hover {
      .icon-arrow-down {
        display: none;
      }
      .icon-arrow-up {
        display: block;
      }
    }
  }

  .nav-item-container {
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: 0px;
      left: -10px;
      right: 0px;
      height: 70px;
    }

    &:hover .nav-item-list,
    &:hover:after {
      display: block;
    }
  }

  .nav-item-list {
    display: none;
    position: absolute;
    right: 0;
    top: 56px;
    margin-right: 50%;
    padding: 17px 20px;
    background: #fdfdfd;
    box-shadow: 0px 4px 20px rgba(117, 117, 117, 0.1);
    border-radius: 12px;
    transform: translate3d(50%, 0, 0);
  }

  .nav-item-list-item {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    padding: 0 16px;
    color: #495259;
    min-width: 140px;
    height: 37px;
    line-height: 37px;
    border-radius: 6px;

    &:hover {
      background: #f4f7fa;
      color: #9faab9;
    }
  }

  .nav-item-list-item + .nav-item-list-item {
    margin-top: 5px;
  }
`

const StyledLink = styled(NavLink)`
  text-decoration: none;
`

const StyledAbsoluteLink = styled.a`
  text-decoration: none;
`

export default Nav
