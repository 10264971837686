import React, { useEffect, useState } from "react"
import { Pledge } from "."
import { useHistory, useLocation, useParams } from "react-router"
import { ethers } from "ethers"
import { abi, abiSwap, localAddress, shopAddress } from "./abi"
import axios from "axios"
import loadingImg from '../../assets/images/loading.gif'
import "./detail.css"
import CoyModal from "./components/coy-modal"
import { useI18n } from "../../i18n/i18n-react"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Detail = () => {
    const query = useQuery()

    const addr = query.get('addr')
    const i18n = useI18n()
    // 先定义一个provider, 里面包含了钱包信息
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(addr, abi, provider.getSigner())
    const shopContract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())

    // 购买卡片
    const [showOrderCard, setShowOrderCard] = useState(false)

    // nftid 
    const [nftId, setNftId] = useState<any>('')

    // 详情信息
    const [nftInfo, setNftInfo] = useState({})


    const getNftInfo = async (id) => {
        const result = await contract.tokenURI(ethers.utils.parseUnits(id, 0))
        const info = await axios(result)
        if (info && info.data) {
            setNftInfo(old => ({
                ...old,
                ...info.data
            }))
        }
    }

    const getNftPrice = async (id) => {
        const result = await shopContract.nftList(addr, ethers.utils.parseUnits(id, 0))
        if (result && result.length >= 2) {
            console.log('获取价格: ', result)
            console.log('价格: ', result[1], '; 字符串: ', result[1].toString())
            setNftInfo(old => ({
                ...old,
                price: parseInt(result[1].toString()) / 1000000000000000000
            }))
        }
    }

    const buyNft = async () => {
        const res = await shopContract.purchase(addr, nftId, {
            value: ethers.utils.parseEther('0.001' || nftInfo.price)
        })
        await res.wait()
        console.log('result: ', res)
    }

    useEffect(() => {
        if (query.get('id')) {
            setNftId(query.get('id'))
            getNftInfo(query.get('id'))
            getNftPrice(query.get('id'))
        }
    }, [query])

    return <Pledge>
        <div className="detail">
            <div className="detail__img">
                <img className="" src={nftInfo.image} />
                <div className="detail__img__bottom">
                    <div className="detail__img__icon">
                        <img src="https://token-monitor.s3.amazonaws.com/1695037958025icon.png" alt="" />
                    </div>
                    <div className="detail__img__info">
                        <div className="detail__img__title">{nftInfo.price}</div>
                        <div className="detail__img__subtitle">{i18n('10044', "Current Price")}</div>
                    </div>
                </div>
            </div>
            <div className="detail__info">
                <div className="detail__info__title">{nftInfo.name} #{nftId}</div>
                <div className="detail__info__subtitle">{nftInfo.description}</div>
            </div>
            <div className="detail__card">
                <div className="detail__card__item">
                    <div className="detail__card__item__title">{i18n('10045', "Contract Address")}</div>
                    {/* <div className="detail__card__item__subtitle">0x182E****F28073</div> */}
                    <div className="detail__card__item__subtitle">{addr.slice(0, 6) + '****' + addr.slice(36, addr.length)}</div>
                </div>
                <div className="detail__card__item">
                    <div className="detail__card__item__title">{i18n('10046', 'Blockchain')}</div>
                    <div className="detail__card__item__subtitle">Core</div>
                </div>
                <div className="detail__card__item">
                    <div className="detail__card__item__title">{i18n('10047', 'Token Standard')}</div>
                    <div className="detail__card__item__subtitle">ERC721</div>
                </div>
            </div>
            <div style={{
                height: 80
            }}></div>
            {showOrderCard && <BuyModal
                nftId={nftId}
                nftInfo={nftInfo}
                closeEvent={() => {
                    setShowOrderCard(false)
                }}
            />}
            {/* {showOrderCard && <div className="ordercard">
                <div className="ordercard__mask" onClick={() => {
                    setShowOrderCard(false)
                }}></div>
                <div className="ordercard__content">
                    <div className="ordercard__header">
                        <div>购买</div>
                        <img onClick={() => {
                            setShowOrderCard(false)
                        }} className="ordercard__header__close" src="https://token-monitor.s3.amazonaws.com/1695037897151Fs2OHtKlsjEqvVyN529bD80x83uE" alt="" />
                    </div>
                    <div className="ordercard__info">
                        <img src={nftInfo.image} alt="" style={{
                            width: 68,
                            height: 68,
                            marginRight: 15
                        }} />
                        <div>{nftInfo.name} #{nftId}</div>
                    </div>
                    <div className="ordercard__price">
                        <div className="ordercard__price__header">
                            <span>您要支付</span>
                            <div className="ordercard__price__price">
                                <div className="detail__img__logo" style={{
                                    width: 20,
                                    height: 20
                                }}></div>
                                {nftInfo.price}C
                            </div>
                        </div>
                        <div style={{
                            color: '#30d5c1'
                        }}>您的COY余额：0.00COY</div>
                    </div>
                    <div className="ordercard__bottom">
                        <div className="ordercard__bottom__item" onClick={() => {
                            setShowOrderCard(false)
                        }}>取消</div>
                        <div className="ordercard__bottom__item ordercard__bottom__submit" onClick={buyNft} >购买</div>
                    </div>
                </div>
            </div>} */}
            <div className="detail__bottom">
                <div className="detail__img__icon" style={{
                    width: 24,
                    height: 24
                }}>
                    <img src="https://token-monitor.s3.amazonaws.com/1695037958025icon.png" alt="" />
                </div>
                <div className="detail__bottom__price">{nftInfo.price} C</div>
                <div className="detail__bottom__btn" onClick={() => {
                    setShowOrderCard(true)
                }}>{i18n('10043', 'Buy')}</div>
            </div>
        </div>
    </Pledge>
}

const BuyModal = ({
    nftId,
    nftInfo,
    closeEvent
}) => {

    // 先定义一个provider, 里面包含了钱包信息
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const shopContract = new ethers.Contract(shopAddress, abiSwap, provider.getSigner())
    const [isLoading, setLoading] = useState(false)
    const [balance, setBalance] = useState(0)
    const [showToast, setShowToast] = useState('')
    const i18n = useI18n()

    const query = useQuery()
    const addr = query.get('addr')
    const getBalance = async () => {
        // const res: any = await provider.getBalance(localAddress)
        // const res1: any = await provider.getBalance(shopAddress)
        const res2: any = await provider.getBalance(provider.provider.selectedAddress)
        if (res2) {
            setBalance(res2 / Math.pow(10, 18))
        }
    }

    const buyNft = async () => {
        if (isLoading) {
            return
        }
        setLoading(true)
        try {
            console.log('nf', nftInfo.price)
            const res = await shopContract.purchase(addr, nftId, {
                value: ethers.utils.parseEther(nftInfo.price + '')
            })
            await res.wait()
            setLoading(false)
            // setShowToast('购买成功')
            setShowToast(i18n('10054', 'Success'))
            setTimeout(() => {
                closeEvent()
            }, 1000)
        } catch (err) {
            setLoading(false)
            setShowToast(i18n('10052', 'An error occurred. Please try again'))
            // setShowToast('发生错误，请重试')
        }
    }

    useEffect(() => {
        getBalance()
    }, [])

    return <div className="ordercard">
        <div className="ordercard__mask" onClick={closeEvent}></div>
        <div className="ordercard__content">
            <div className="ordercard__header">
                <div>{i18n('10043', "Buy")}</div>
                <img onClick={closeEvent} className="ordercard__header__close" src="https://token-monitor.s3.amazonaws.com/1695037897151Fs2OHtKlsjEqvVyN529bD80x83uE" alt="" />
            </div>
            <div className="ordercard__info">
                <img src={nftInfo.image} alt="" style={{
                    width: 68,
                    height: 68,
                    marginRight: 15
                }} />
                <div>{nftInfo.name} #{nftId}</div>
            </div>
            <div className="ordercard__price">
                <div className="ordercard__price__header">
                    <span>{i18n('10049', 'You want to pay')}</span>
                    <div className="ordercard__price__price">
                        <div className="detail__img__icon" style={{
                            width: 20,
                            height: 20
                        }}>
                            <img src="https://token-monitor.s3.amazonaws.com/1695037958025icon.png" alt="" />
                        </div>
                        {nftInfo.price}C
                    </div>
                </div>
                <div style={{
                    color: '#30d5c1'
                }}>{i18n('10050', 'Your CORE balance')}：{balance}</div>
            </div>
            <div className="ordercard__bottom">
                <div className="ordercard__bottom__item" onClick={closeEvent}>{i18n('10048', 'Cancel')}</div>
                <div className="ordercard__bottom__item ordercard__bottom__submit" onClick={buyNft} >{i18n('10043', 'Buy')}</div>
            </div>
        </div>
        {/* {isLoading && <div className="bagloading">
            <img src={loadingImg} alt="" />
        </div>} */}
        {isLoading && <CoyModal type="loading" />}
        {showToast && <CoyModal type="toast" text={showToast} closeEvent={() => {
            setShowToast('')
        }} />}
    </div>
}

export default Detail