import { ChainId } from '@pancakeswap-libs/sdk'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { NERWORK_URLS } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useNetworkModalToggle } from '../../state/application/hooks'
import { NetworkSwitchModal } from './NetworkSwitchModal'

const Container = styled.div`
  background: #51ccc5;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 14px;
  height: 40px;
  padding: 0 20px;
  margin: 0 10px;
  cursor: pointer;
`

export const NetworkButton = () => {
  const { chainId } = useActiveWeb3React()
  console.log('当前chainId', chainId)
  const toggleNetworkModal = useNetworkModalToggle()
  const displayNetwork = useMemo(() => {
    if (chainId === ChainId.HECO_MAINNET) {
      return 'HECO'
    }
    if (chainId === ChainId.OEC_MAINNET) {
      return 'OEC'
    }
    if (chainId === ChainId.CORE_MAINNET) {
      return 'CORE'
    }
    if (chainId === ChainId.POLYGON) {
      return 'Polygon'
    }
    if (chainId === ChainId.BSC_MAINNET) {
      return 'BSC'
    }
    if (chainId === ChainId.BSC_TESTNET) {
      return 'BSC-Test'
    }
  }, [chainId])

  useEffect(() => {
    window.ethereum?.on('chainChanged', _chainId => {
      const chainId = Number(_chainId)
      if (
        chainId !== ChainId.HECO_MAINNET &&
        chainId !== ChainId.OEC_MAINNET &&
        chainId !== ChainId.POLYGON &&
        chainId !== ChainId.BSC_MAINNET &&
        chainId !== ChainId.BSC_TESTNET &&
        chainId !== ChainId.CORE_MAINNET
      )
        return
      console.log(chainId)
      window.localStorage.setItem('chainId', chainId.toString())
      window.localStorage.setItem('networkUrl', NERWORK_URLS[chainId])
      window.location.reload()
    })
  }, [])

  return (
    <>
      <Container onClick={toggleNetworkModal}>{displayNetwork}</Container>
      <NetworkSwitchModal />
    </>
  )
}
